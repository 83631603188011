/** @jsx jsx */

import { css, jsx } from '@emotion/react'
import { graphql, useStaticQuery } from 'gatsby'

import { Container } from 'src/components/common/Container'
import { Squidex_Technologies } from 'src/types/gatsby-types'
import { TechnologiesItem } from 'src/components/Technologies/TechnologiesItem'
import { TechnologiesList } from 'src/components/TechnologiesList/TechnologiesList'
import { motion } from 'framer-motion'
import { mq } from 'src/utils/mq'

const wrapper = css`
  ${mq.md} {
    position: relative;
  }
  ${mq.lg} {
    padding: 0 45px;
  }
`

type Data = {
  squidex: {
    queryTechnologiesContents: Squidex_Technologies[]
  }
}

export const Technologies: React.FC = () => {
  const response: Data = useStaticQuery(
    graphql`
      query {
        squidex {
          queryTechnologiesContents {
            flatData {
              blocks {
                flatData {
                  description
                  title
                  contentCssClass
                  linkText
                  linkHref
                  isButtonPrimary
                  buttonText
                  buttonLink
                  illustration {
                    url
                    slug
                    id
                    metadata(path: "description")
                  }
                }
                id
              }
            }
          }
        }
      }
    `,
  )

  const data = response?.squidex?.queryTechnologiesContents?.[0]

  if (!data) {
    throw new Error('No data for Technologies blocks')
  }

  return (
    <div css={{ overflow: 'hidden' }}>
      <Container css={wrapper}>
        {data.flatData.blocks?.map((item) => (
          <motion.div
            initial={{ y: 100, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.4 }}
            key={item.id}
          >
            <TechnologiesItem data={item} />
          </motion.div>
        ))}
      </Container>
      <motion.div
        initial={{ y: 50, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.4 }}
      >
        <TechnologiesList full />
      </motion.div>
    </div>
  )
}
