import { ButtonLink } from 'src/components/Button/Button'
import { Layout } from 'src/components/Layout/Layout'
import { PageProps } from 'gatsby'
import React from 'react'
import { ReadyToStart } from 'src/components/ReadyToStart/ReadyToStart'
import { Technologies } from 'src/components/Technologies/Technologies'
import { path } from 'src/utils/path'

const TechnologiesPage: React.FC<PageProps<{}>> = () => {
  return (
    <Layout title="Technologies | RabbitPeepers">
      <Technologies />
      <ReadyToStart
        title="Not sure what you need?"
        subtitle="Get our technical advisory"
        showContactUs={false}
        button={
          <ButtonLink to={path.contactUs} variant="contained" size="large">
            Talk to an expert
          </ButtonLink>
        }
      />
    </Layout>
  )
}

export default TechnologiesPage
