/** @jsx jsx */
import { ReadyToStartContent, ReadyToStartProps } from 'src/components/ReadyToStart/ReadyToStartContent'
import { css, jsx } from '@emotion/react'

import { Container } from 'src/components/common/Container'
import { ShadowBox } from 'src/components/common/ShadowBox'
import { mq } from 'src/utils/mq'

const wrapper = css`
  padding: 0 16px;
  margin: 85px auto 75px;

  ${mq.md} {
    margin-top: 96px;
  }
`

export const ReadyToStart: React.FC<ReadyToStartProps> = (props) => {
  return (
    <Container css={wrapper}>
      <ShadowBox hasShadowOnMobile>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <ReadyToStartContent {...props} />
      </ShadowBox>
    </Container>
  )
}
